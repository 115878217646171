import React from 'react';
import ImageCard from './ImageCard';

interface Props {
  src: string;
  alt?: string;
  className: string;
}

export const ImageThumbnail: React.FC<Props> = ({
  src, alt = 'Photo Thumbnail', className,
}) => (
  <ImageCard src={src} alt={alt} className={`object-cover ${className}`} />
);

export default ImageThumbnail;
