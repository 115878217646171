/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import getApiKey from 'packs/common/getApiKey';
import { snakeCaseKeys } from './snakeCaseKeys';
import { camelizeKeys } from './camelizeKeys';

type SnakeCaseable = Record<string, unknown>;

const baseURL = '/api/v2';
const apiClient: AxiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'X-Platform': 'Browser',
    'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'X-Csrf': getApiKey(),
  },
});

apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  // form-data is not a json object so don't transform it
  if (config.headers['Content-Type'] === 'multipart/form-data') { return config; }

  if (config.data && typeof config.data === 'object') {
    config.data = snakeCaseKeys(config.data as SnakeCaseable);
  }
  if (config.params && typeof config.params === 'object') {
    config.params = snakeCaseKeys(config.params as SnakeCaseable);
  }
  return config;
});

apiClient.interceptors.response.use((response) => {
  if (response.data && typeof response.data === 'object') {
    response.data = camelizeKeys(response.data);
  }
  return response;
});

export const handleApiError = (error: AxiosError<{ error?: string }>): string => (
  error.response?.data?.error || error.message || 'An error occurred'
);

export const getErrorMessage = (err: unknown): string => {
  const error = err as AxiosError<{ error?: string }>;
  return handleApiError(error);
};

export default apiClient;
