import React, { useState } from 'react';
import { CaratDown, CaratUp } from 'ui/components/Icons';

interface Props {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  children: React.ReactNode;
  expanded?: boolean;
  caratPosition?: 'center' | 'start';
  titleClasses?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

export const Accordion: React.FC<Props> = ({
  title, subtitle, children, onOpen = null, onClose = null,
  expanded = false, caratPosition = 'center', titleClasses = '',
}) => {
  const [open, setOpen] = useState<boolean>(expanded);
  const caratAlignment = caratPosition === 'center' ? 'items-center' : 'items-start';
  const bottomBorder = titleClasses.includes('rounded-t-lg') && open ? '' : 'rounded-b-lg';

  const handleToggle = () => {
    if (open === false && onOpen) { onOpen(); }
    if (open === true && onClose) { onClose(); }

    setOpen(!open);
  };

  return (
    <section data-testid="accordion">
      <div
        role="button"
        tabIndex={0}
        onClick={handleToggle}
        onKeyDown={e => e.key === 'Enter' && handleToggle()}
      >
        <div className={`flex justify-between ${caratAlignment} ${titleClasses} ${bottomBorder}`}>
          <div>
            {title}
            {!!subtitle && subtitle}
          </div>

          <div className="min-w-[20px]">
            {!open && <CaratDown width="w-[20px]" height="h-[12px]" />}
            {open && <CaratUp width="w-[20px]" height="h-[12px]" />}
          </div>
        </div>
      </div>

      <div className={`${open ? '' : 'hidden'}`}>{children}</div>
    </section>
  );
};

export default Accordion;
