import React from 'react';

interface Props {
  children: React.ReactNode;
  backgroundColor?: string;
  extraClasses?: string;
}

export const Card: React.FC<Props> = ({
  children, backgroundColor = 'bg-white', extraClasses = '',
}) => (
  <section
    className={`flex flex-col grow px-6 py-4 w-full ${backgroundColor} rounded-lg max-md:px-5 max-md:max-w-full ${extraClasses}`}
  >
    {children}
  </section>
);

export default Card;
