import { camelize } from 'inflection';

const isObject = (value: unknown): value is Record<string, unknown> => (
  typeof value === 'object' && value !== null && !Array.isArray(value)
);

type Camelizable = Record<string, unknown> | unknown[];

export const camelizeKeys = (obj: unknown): Camelizable => {
  if (Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return obj.map(item => (isObject(item) || Array.isArray(item) ? camelizeKeys(item) : item)) as unknown[];
  }

  if (isObject(obj)) {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
      const camelizedKey = camelize(key, true);
      const camelizedValue = isObject(value) || Array.isArray(value) ? camelizeKeys(value) : value;
      return [camelizedKey, camelizedValue];
    })) as Record<string, unknown>;
  }

  return obj as Camelizable;
};

export default camelizeKeys;
