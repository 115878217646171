import { Feature, FeatureSettings } from 'ui/types';

export const hasFeature = (feature: Feature, featureSettings: FeatureSettings | undefined): boolean => (
  !!featureSettings && (featureSettings[feature] || false)
);

export const anyFeatures = (features: Feature[], featureSettings: FeatureSettings | undefined): boolean => (
  features.some(feature => hasFeature(feature, featureSettings))
);

export const allFeatures = (features: Feature[], featureSettings: FeatureSettings | undefined): boolean => (
  features.every(feature => hasFeature(feature, featureSettings))
);
