import { underscore } from 'inflection';

const isObject = (value: unknown): value is Record<string, unknown> => (
  typeof value === 'object' && value !== null && !Array.isArray(value)
);

type SnakeCaseable = Record<string, unknown> | unknown[];

export const snakeCaseKeys = (obj: unknown): SnakeCaseable => {
  if (Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return obj.map(item => (isObject(item) || Array.isArray(item) ? snakeCaseKeys(item) : item));
  }

  if (isObject(obj)) {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
      const snakeCaseKey = underscore(key);
      const snakeCaseValue = isObject(value) || Array.isArray(value) ? snakeCaseKeys(value) : value;
      return [snakeCaseKey, snakeCaseValue];
    })) as Record<string, unknown>;
  }

  return obj as SnakeCaseable;
};

export default snakeCaseKeys;
