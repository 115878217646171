import React from 'react';
import classNames from 'classnames';

interface Props {
  title: string;
  disabled?: boolean;
}

export const SubmitButton: React.FC<Props> = ({ title, disabled = false }) => (
  <button
    type="submit"
    disabled={disabled}
    className={classNames('flex items-center rounded-3xl px-3 btn-primary', { 'btn-disabled': disabled })}
  >
    <span className="py-1">{title}</span>
  </button>
);

export default SubmitButton;
