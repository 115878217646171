import { Vocabs } from 'ui/types';

type VocabTypes =
'point' | 'plan_design_point' | 'challenge' | 'todo' |
'location' | 'department' | 'job' | 'captain';

const getVocab = (vocab: VocabTypes, vocabs: Vocabs): string => (
  vocabs[vocab] || vocab
);

export default getVocab;
