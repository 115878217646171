const isCrossDomain = (url) => {
  const originAnchor = document.createElement('a');
  originAnchor.href = location.href; // eslint-disable-line no-restricted-globals
  const urlAnchor = document.createElement('a');

  try {
    urlAnchor.href = url;
    const invalidProtocol = !urlAnchor.protocol || urlAnchor.protocol === ':';
    const missingHost = !urlAnchor.host;
    const domainMatch = `${originAnchor.protocol}//${originAnchor.host}` === `${urlAnchor.protocol}//${urlAnchor.host}`;
    if (invalidProtocol || missingHost || !domainMatch) { return true; }

    return false;
  } catch (e) {
    return true;
  }
};

export const submitByMethod = (e, method) => {
  const link = e.currentTarget;
  const { href } = link;
  if (!href) return;

  const csrfToken = document.querySelector('meta[name=csrf-token]').content;
  const csrfParam = document.querySelector('meta[name=csrf-param]').content;

  const form = document.createElement('form');
  form.method = 'post';
  form.action = href;
  form.target = link.target;
  form.style.display = 'none';

  const methodInput = document.createElement('input');
  methodInput.name = '_method';
  methodInput.value = method;
  methodInput.type = 'hidden';
  form.appendChild(methodInput);

  if (csrfParam && csrfToken && !isCrossDomain(href)) {
    const csrfInput = document.createElement('input');
    csrfInput.name = csrfParam;
    csrfInput.type = 'hidden';
    csrfInput.value = csrfToken;
    form.appendChild(csrfInput);
  }

  const submitInput = document.createElement('input');
  submitInput.type = 'submit';
  form.appendChild(submitInput);
  document.body.appendChild(form);
  submitInput.click();
  e.preventDefault();
  e.stopPropagation();
};

export default submitByMethod;
