import React from 'react';

interface Props {
  src: string;
  alt?: string;
  title?: string;
  className?: string;
}

const ImageCard: React.FC<Props> = ({
  src, alt = 'Image', title = '', className = '',
}) => (
  <div>
    <img
      loading="lazy"
      src={src}
      alt={alt}
      className={className}
    />
    {!!title && <div className="self-center mt-4">{title}</div>}
  </div>
);

export default ImageCard;
