import React from 'react';

interface Props {
  image: string;
  name: string;
  email?: string;
}

const RecipientOption: React.FC<Props> = ({
  name, image, email = '',
}) => (
  <div className="recipient-option" data-select-option>
    <div className="flex gap-2">
      <img src={image} width={25} height={25} alt="" />
      <span>{name}</span>
    </div>

    <span className="ms-2 text-dark-gray">{email}</span>
  </div>
);

export default RecipientOption;
