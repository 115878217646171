export const fitnesses = [
  'Ab Circle',
  'Aerobic Dance',
  'Aerobics',
  'Archery',
  'Backpacking',
  'Badminton',
  'Balance Ball',
  'Ballet',
  'Ballroom dancing',
  'Bar Method',
  'Baseball',
  'Basketball',
  'Bicycling',
  'Bocce',
  'Body Pump',
  'Boot Camp',
  'Bowflex',
  'Bowflex Treadclimber',
  'Bowling',
  'Boxing - non-competitive',
  'Boxing, competitive',
  'Calisthenics',
  'Canoeing',
  'Cheerleading',
  'Circuit Training',
  'Climbing - rock or mountain',
  'Croquet',
  'Cross-country Skiing',
  'CrossFit',
  'Curling',
  'Curves',
  'Cycling',
  'Dancing',
  'Disc Golf',
  'Diving',
  "Dojo's Interval Training",
  "Dojo's Kickboxing",
  'Downhill Skiing',
  'Elliptical Trainer',
  'Fencing',
  'Field Hockey',
  'Fishing',
  'Foot Bag',
  'Foot Golf',
  'Football, touch or flag',
  'Frisbee',
  'Freeletics',
  'FXB Kickboxing',
  'FXB Resistance Training',
  'Gardening',
  'Golfing',
  'Gymnastics',
  'Handball',
  'Hiking',
  'Horseback Riding',
  'Horshoe Pitching',
  'Hot Yoga',
  'Housekeeping',
  'Hula Hooping',
  'Ice Hockey',
  'Ice Skating',
  'In-line Skating',
  'Insanity Workout',
  'Interval Training',
  'Jai Alai',
  'Jazzercise',
  'Judo',
  'Jumping Rope',
  'Kayaking',
  'Kettlenetics',
  'Kickball',
  'Kickboxing',
  'Kosama',
  'Lacrosse',
  'Lunges',
  'Martial Arts',
  'Mountain Biking',
  'Mowing the lawn (push mow)',
  'Next Level Xtreme Fitness',
  'P31',
  'P90X',
  'Paddleball',
  'Paintball',
  'Pickleball',
  'Pilates',
  'Platform Tennis',
  'Power Walking',
  'Power Yoga',
  'Precision Horse Events',
  'Punching Bag',
  'Push-Ups',
  'Raking Leaves or Grass',
  'Raquetball',
  'Rock-climbing',
  'Roller Skating',
  'Rowing',
  'Rugby',
  'Running',
  'Sailing',
  'Scuba Diving',
  'ShakeOut',
  'Sit-ups',
  'Skateboarding',
  'Ski Machine',
  'Skiing',
  'Snorkeling',
  'Snow Shoveling',
  'Snowboarding',
  'Snowshoeing',
  'Soccer',
  'Softball',
  'Spinning',
  'Square dancing',
  'Squash',
  'Squats',
  'Stability Ball',
  'Stair Stepping',
  'Stand Up Paddle Boarding',
  'Stationary',
  'Strength Training',
  'Stretching',
  'Surfing',
  'Swimming',
  'T 25',
  'Table Tennis',
  'Tai Chi',
  'Tennis',
  'Total Body- Low Impact',
  'Total Gym',
  'Trampoline',
  'Treadmill',
  'TurboFire',
  'Unicycling',
  'Vinyasa Yoga',
  'Volleyball',
  'Walking',
  'Wallyball',
  'Water Aerobics',
  'Water Jogging',
  'Water Polo',
  'Water Skiing',
  'Weight Lifting',
  'Whitewater Rafting',
  'Wii Fitness',
  'Wind Surfing',
  'Wrestling',
  'Xbox 360 Kinect Fitness',
  'Yard Work',
  'Yoga',
  'Zumba',
];

export default fitnesses;
