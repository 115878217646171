import React from 'react';
import classNames from 'classnames';
import { submitByMethod } from 'ui/utils';

interface Props {
  to: string;
  children: React.ReactNode;
  disabled?: boolean;
  target?: string;
  method?: string | undefined;
  label?: string;
  role?: string;
  tabIndex?: number;
  className?: string;
  testId?: string;
}

export const Link: React.FC<Props> = ({
  to, children, disabled = false, target = '_self', method,
  label = 'Link', role = 'link', tabIndex = 0, className = '', testId = '',
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled || to === '') {
      e.preventDefault();
      return;
    }

    if (method) {
      submitByMethod(e, method);
    }
  };

  return (
    <a /* eslint-disable-line jsx-a11y/anchor-is-valid */
      href={disabled ? '#' : to}
      target={target}
      onClick={handleClick}
      aria-label={label}
      role={role}
      tabIndex={tabIndex}
      className={classNames(className, { 'btn-disabled cursor-not-allowed': disabled })}
      data-testid={testId}
    >
      {children}
    </a>
  );
};

export default Link;
