import { ReactSelectChevron } from 'ui/components/Common/ReactSelectChevron';
import { ReactSelectOption } from 'ui/types';

export const formatOption = (option: string): ReactSelectOption => ({ value: option, label: option });

export const overrideControl = base => (
  {
    ...base,
    border: "1px solid 'rgb(var(--color-mid-gray))'",
    height: '100%',
    padding: '0.2rem',
    borderWidth: '1px',
    width: '100%',
    color: 'rgb(var(--color-black))',
    backgroundColor: 'rgb(var(--color-white))',
  }
);

export const overrideThemes = theme => (
  {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: 'rgb(var(--color-mid-light-gray))',
      primary: 'rgb(var(--color-mid-dark-gray))',
    },
  }
);

export const overrideComponents = () => (
  {
    IndicatorSeparator: null,
    DropdownIndicator: ReactSelectChevron,
  }
);
