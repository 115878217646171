import React from 'react';

interface Props {
  count: number;
}

export const SkeletonCard: React.FC<Props> = ({ count }) => (
  <div data-testid="skeleton-card-container" className="grid grid-cols-3 gap-6 w-full max-w-[1158px] mt-14 max-md:grid-cols-1">
    {Array.from({ length: count }, (_, index) => (
      <div
        key={index}
        className="bg-gray-200 rounded-lg p-4 h-48 animate-pulse"
      />
    ))}
  </div>
);

export default SkeletonCard;
