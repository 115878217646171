import React from 'react';

interface Props {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  totalResults: number;
  resultsPerPage: number;
}

export const Pagination: React.FC<Props> = ({
  currentPage,
  totalPages,
  onPageChange,
  totalResults,
  resultsPerPage,
}) => {
  const handlePreviousClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const pageButtons = () => {
    const pages: number[] = Array.from({ length: totalPages }, (_, i) => i + 1);
    const index = pages.indexOf(currentPage);
    const start = Math.max(index - 2, 0);
    const end = Math.min(index + 2, pages.length - 1);

    const result: number[] = [];
    for (let i = start; i <= end; i += 1) {
      result.push(pages[i]);
    }

    return result;
  };

  return (
    <nav
      aria-label="Pagination"
      className="flex flex-col justify-center items-center space-x-4 mt-8"
    >
      <div className="px-4 py-2 text-right">
        <button
          type="button"
          onClick={handlePreviousClick}
          disabled={currentPage === 1}
          className="px-4 py-2 mx-1 text-neutral-500"
        >
          Back
        </button>
        {pageButtons().map(index => (
          <button
            type="button"
            key={index}
            onClick={() => onPageChange(index)}
            className={
              `px-4 py-2 mx-1 rounded-lg border-2 ${currentPage === index ?
                'border-primary text-primary' :
                'border-stone-300'
              }`
}
          >
            {index}
          </button>
        ))}
        <button
          type="button"
          onClick={handleNextClick}
          disabled={currentPage === totalPages}
          className="px-4 py-2 text-primary"
        >
          Next
        </button>
      </div>

      <div className="px-4 py-2 text-right">
        {(currentPage - 1) * resultsPerPage + 1} -{' '}
        {Math.min(currentPage * resultsPerPage, totalResults)} of {totalResults}
      </div>
    </nav>
  );
};

export default Pagination;
